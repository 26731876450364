/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Redirect } from '@reach/router';

// UI lib components
import { Container } from 'react-grid-system';

// UI local components
import PageLayout from '../shared/PageLayout';
import Seo from '../shared/Seo';
import ExhibitionLayout from '../page-components/Exhibition/ExhibitionLayout';

// Style
import '../page-styles/exhibition.scss';

/* -------------------------------------------------------------------------- */
/*                                 Movie Page                                */
/* -------------------------------------------------------------------------- */

function ExhibitionPage({ params }) {
  /* ********************************** HOOKS ********************************* */

  // Localization
  const { t } = useTranslation();

  /* ******************************** RENDERING ******************************* */
  return params.id ? (
    <PageLayout>
      <Seo title={t('title')} />
      <Container className="fluid exhibition-page-container" fluid>
        <ExhibitionLayout id={params.id} />
      </Container>
    </PageLayout>
  ) : (
    <Redirect from="/exhibition" to="/" />
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["ExhibitionPage", "Common"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

ExhibitionPage.propTypes = {
  params: PropTypes.oneOfType([PropTypes.object]),
};
ExhibitionPage.defaultProps = {
  params: undefined,
};

export default ExhibitionPage;
